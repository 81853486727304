import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

function displayDate(date, locale) {
  const d = new Date(date);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return d.toLocaleDateString(locale, options);
}

export default function Blog({ data }) {
  const { i18n } = useTranslation();

  return (
    <Layout>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold font-display text-chocolate sm:text-4xl">
              <Trans>Amplified Blog</Trans>
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray_4.5 sm:mt-4">
              <Trans>
                IP strategy, working with patent data, and artificial
                intelligence
              </Trans>
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {data.allContentfulArticle.edges.map(({ node: post }) => {
              return (
                <div
                  key={post.title}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <Link
                    to={`/${post.node_locale}/${post.type}/${post.pageDate}/${post.slug}`}
                  >
                    <div className="flex-shrink-0">
                      {post.mainImage && (
                        <div className="h-48 w-full overflow-hidden object-contain">
                          <GatsbyImage
                            image={getImage(post.mainImage.gatsbyImageData)}
                            className="w-full"
                          />
                        </div>
                      )}
                    </div>
                  </Link>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-bright_gold_120 capitalize">
                        {post.type}
                      </p>
                      <Link
                        to={`/${post.node_locale}/${post.type}/${post.pageDate}/${post.slug}`}
                      >
                        <p className="text-xl text-chocolate font-display">
                          {post.title}
                        </p>
                        <p className="mt-3 text-base text-gray_4.5">
                          {post.description && post.description.description}
                        </p>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      {post.author && post.author.headshot && (
                        <div className="flex-shrink-0">
                          <span className="sr-only">{post.author.name}</span>
                          <GatsbyImage
                            className="h-10 w-10 rounded-full"
                            image={getImage(
                              post.author.headshot.gatsbyImageData
                            )}
                            alt={post.author.name}
                          />
                        </div>
                      )}
                      <div className="ml-3">
                        {post.author && (
                          <p className="text-sm font-medium text-gray_5">
                            {post.author.name}
                          </p>
                        )}
                        <div className="flex space-x-1 text-sm text-gray_4">
                          {displayDate(post.date, i18n.language)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    allContentfulArticle(
      filter: { node_locale: { eq: $language }, hide: { eq: false } }
      sort: { fields: [publicationDate], order: [DESC] }
    ) {
      edges {
        node {
          title
          type
          description {
            description
          }
          date: publicationDate
          pageDate: publicationDate(formatString: "DDMMYYYY")
          node_locale
          author {
            name
            headshot {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          mainImage {
            gatsbyImageData(layout: CONSTRAINED)
          }
          slug
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
